<template>
  <AFilterCategoryItems
    v-if="isActive"
    :items="items"
    :level="1"
    :show-all-goods="true"
    :show-all-title="headerTitle"
    @select="selectedCategory"
  />
</template>
<script>

import AFilterCategoryItems from 'theme/components/atoms/a-filter-category-items';

export default {
  name: 'MPromotionFilter',
  components: {
    AFilterCategoryItems
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isActive: {
      type: Boolean,
      default: false
    },
    current: {
      type: Object,
      default: () => ({})
    },
    headerTitle: {
      type: String,
      default: ''
    }
  },
  provide () {
    const provided = {};

    // Reactive injection
    Object.defineProperties(provided, {
      activeCategoryId: {
        get: () => this.promotionCurrentCategory
      }
    })

    return { provided };
  },
  computed: {
    promotionCurrentCategory () {
      if (this.current !== undefined) {
        if (this.current.hasOwnProperty('cat')) {
          return Array.isArray(this.current.cat) ? Number(this.current.cat[0])
            : Number(this.current.cat)
        } else {
          return Array.isArray(this.current.type) ? Number(this.current.type[0])
            : Number(this.current.type)
        }
      } else {
        return null
      }
    }
  },
  methods: {
    selectedCategory (category) {
      this.$emit('select', category)
    }
  }
}
</script>
