<template>
  <div
    class="m-promotion-product"
    :class="{'m-promotion-product__out': timer(item.date_to).distance === 0}"
  >
    <SfLink
      :link="localizedRoute('/promotion/' + item.slug)"
    >
      <div class="m-promotion-product-images">
        <picture>
          <source
            :srcset="getPicturePath(item.desktop_image)"
            :media="`(min-width: ${getBreakpointSm}px)`"
          >
          <img
            :class="{'action-has-image': (isDesktop && item.desktop_image) || (!isDesktop && item.mobile_image)}"
            :src="getPicturePath(item.mobile_image)"
            :alt="item.label_text"
            :loading="loadingAttribute"
            :fetchpriority="priority"
          >
        </picture>
        <div
          class="m-promotion-product-timer"
          v-if="timer(item.date_to).days !== 0"
        >
          {{ timer(item.date_to).days }}
          {{ labelDay(timer(item.date_to).days) }}
          <span>{{ start }}</span>
        </div>
      </div>
      <p
        v-if="timer(item.date_to).distance === 0"
        class="m-promotion-product__date m-promotion-product__date_end"
      >
        {{ $t("Promotion ended") }}
      </p>
      <p
        v-else
        class="m-promotion-product__date"
      >
        {{ $t("from promotion") }}
        {{ formatDate(item.date_from) }}
        {{ $t("By") }}
        {{ formatDate(item.date_to) }}
      </p>
      <p class="m-promotion-product__title">
        {{ item.name }}
      </p>
      <p class="m-promotion-product__subtitle">
        {{ item.short_description }}
      </p>
    </SfLink>
  </div>
</template>
<script>
import { getThumbnailPath } from '@vue-storefront/core/helpers';
import { BREAKPOINT_SM } from 'theme/helpers/breakpoints';
import { declension } from 'theme/helpers/text';
import { getFormatTimer } from 'theme/helpers/timer';
import { SfLink } from '@storefront-ui/vue';
import DeviceType from 'theme/mixins/DeviceType';

export default {
  name: 'MPromotionProduct',
  components: {
    SfLink
  },
  mixins: [DeviceType],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    start: {
      type: Number,
      default: 0
    },
    isLazy: {
      type: Boolean,
      default: true
    },
    isPriority: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      startTime: {}
    }
  },
  computed: {
    getBreakpointSm () {
      return BREAKPOINT_SM
    },
    loadingAttribute () {
      return this.isLazy ? 'lazy' : 'eager'
    },
    priority () {
      return this.isPriority ? 'high' : 'auto'
    }
  },
  methods: {
    getPicturePath (url) {
      return getThumbnailPath(url)
    },
    formatDate (date) {
      const newDate = new Date(date)
      const day = newDate.getDate()
      const month = newDate.toLocaleString('en', { month: 'long' })
      const year = newDate.getFullYear()
      return this.$t(`{day} ${month} {year}`, { day: day, year: year })
    },
    labelDay (day) {
      return declension([this.$t('day'), this.$t('days'), this.$t('days_')], day)
    },
    timer (date) {
      return getFormatTimer(date)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.m-promotion-product {
  width: 100%;
  margin-bottom: var(--spacer-30);

  @media (min-width: $tablet-min) {
    width: calc(50% - var(--spacer-5));
  }

  &__out {
    opacity: 0.5;
  }

  &-images {
    width: 100%;
    height: px2rem(250);
    position: relative;

    @media (max-width: $tablet-max) {
      height: px2rem(150);
    }

    img {
      width: 100%;
      height: 100%;
    }

    img.action-has-image {
      object-fit: cover;
      object-position: top;
    }
  }

  &-timer {
    position: absolute;
    left: 0;
    top: var(--spacer-25);
    padding: var(--spacer-7) var(--spacer-8);
    background-color: var(--orange);
    font-size: var(--font-size-14);
    font-weight: var(--font-medium);
    color: var(--white);

    span {
      display: none;
    }
  }

  &__date {
    margin: var(--spacer-15) 0 0 0;
    font-size: var(--font-size-13);
    color: var(--dark-gray);
    text-transform: lowercase;

    @media (min-width: $tablet-min) {
      font-size: var(--font-size-14);
    }

    &_end {
      text-transform: initial;
    }
  }

  &__title {
    margin: var(--spacer-5) 0 0 0;
    font-size: var(--font-size-16);
    font-weight: 500;
    color: var(--black);

    @media (min-width: $tablet-min) {
      font-size: var(--font-size-24);
    }
  }

  &__subtitle {
    margin: var(--spacer-5) 0 0 0;
    font-size: var(--font-size-14);
    font-weight: 400;
    color: var(--black);

    @media (min-width: $tablet-min) {
      font-size: var(--font-size-16);
    }
  }
}
</style>
