import { RobotsEnum } from './types'
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import config from 'config';

export function metaPromotion () {
  let metaTitle
  let metaDescription
  let metaKeywords

  if (this.getSelectedCategory) {
    metaTitle = this.getSelectedCategory.meta_title
    metaDescription = this.getSelectedCategory.meta_description
    metaKeywords = this.getSelectedCategory.meta_heywords
  } else {
    metaTitle = this.getPromotionMetaData.category_seo_meta_title
    metaDescription = this.getPromotionMetaData.category_seo_meta_description
    metaKeywords = this.getPromotionMetaData.category_seo_meta_keywords
  }

  const relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);
  const meta = {
    title: metaTitle || `${this.$t('Stock')} | VARUS`,
    meta: {
      ...relRobots,
      description: {
        name: 'description',
        content: metaDescription || `${this.$t('Stock')} ${this.$t('PROMOTION_DESCRIPTION')}`
      },
      keywords: {
        name: 'keywords',
        content: metaKeywords || this.$t('Stock')
      }
    }
  };

  return mergeMeta(meta)
}
